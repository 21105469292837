import AuthBackground from '../assets/auth-background.png';
import CardBackground from '../assets/card-background.png';
import CardScreenBackground from '../assets/cards-screen-bg-2.png';
import CardImage from '../assets/cards/card-image.png';
import LOGO from '../assets/logo.png';
import WhiteLogo from '../assets/white-logo.png';
import colors from './variables.module.scss';

const styles = {
  ...colors,
  // MENU_HEADER_HEIGHT: 58,
  // PROFILE_IMAGE: require('../assets/no_profile_image.jpeg')
  LOGO,
  WhiteLogo,
  CardImage,
  CardBackground,
  CardScreenBackground,
  AuthBackground,
  // PROFILE_IMAGE: require('../assets/no_profile_image.jpeg'),
  animations: {
    phoneCall: require('../assets/animations/PhoneCall.json'),
    noStatements: require('../assets/animations/NoStatements.json'),
    noPayee: require('../assets/animations/NoPayee.json'),
    noNotifications: require('../assets/animations/NoNotifications.json'),
    checkmark: require('../assets/animations/checkmark.json'),
    money: require('../assets/animations/money.json'),
    moneyTransfer: require('../assets/animations/money-transfer.json'),
  },
};

export default styles;
